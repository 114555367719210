import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <Link to="/contact">
        <FontAwesomeIcon icon={faEnvelope} className="socMedia" />
      </Link>
      <a href="https://www.instagram.com/squillace1923/" target="_blanck">
        <FontAwesomeIcon icon={faInstagram} className="socMedia" />
      </a>
    </div>
  );
};

export default Footer;
