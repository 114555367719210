export const schemaHome = {
  "@context": "http://schema.org/",
  "@type": "Website",
  name: "Squillace1923",
  url: "https://www.squillace1923.com/",
  address: "",
  description: "Collection of luxury leather gloves by Squillace1923",
  sameAs: [
    "https://www.facebook.com/Martina-Squillace-1413036258925102/",
    "https://www.linkedin.com/in/martina-squillace-b5859858/?originalSubdomain=fr",
    "https://www.instagram.com/squillace1923/",
  ],
};

// export const schemaHome = {
//   "@context": "http://schema.org/",
//   "@type": "Website",
//   "name": "Squillace1923",
//   "url": "https://www.squillace1923.com/",
//   "address": "",
//   itemReviewed: {
//     "@type": "Product",
//     "@id": "gloves",
//   },
//   author: {
//     "@type": "Person",
//     name: "Squillace",
//   },
//   description: "Collection of luxury leather gloves by Squillace1923",
//   "sameAs": [
//     "https://www.facebook.com/Martina-Squillace-1413036258925102/",
//     "https://www.linkedin.com/in/martina-squillace-b5859858/?originalSubdomain=fr"
//   ]
// };
// <script type="application/ld+json">
//   {
//     "@context": "http://schema.org",
//     "@type": "Organization",
//     "name": "Squillace1923",
//     "url": "https://www.squillace1923.com/",
//     "address": "",
//     "sameAs": [
//       "https://www.facebook.com/Martina-Squillace-1413036258925102/",
//       "https://www.linkedin.com/in/martina-squillace-b5859858/?originalSubdomain=fr"
//     ]
//   }
// </script>
// export const schemaHome = {
//   "@context": "http://schema.org",
//   "@type": "Website",
//   name: "Squillace 1923",
//   url: "https://www.squillace1923.com/",
//   sameAs: ["https://www.instagram.com/squillace1923/"],
// };
