import React, { useEffect } from "react";
//@ts-ignore
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const proprietes = {
  duration: 4000,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  pauseOnHover: false,
  canSwipe: false,
};

const Slideshow = () => {
  const picSlideshowList =
    window.screen.width < 850
      ? [
          "katabasis/Katabasis9.jpg",
          "katabasis/Katabasis3.jpg",
          "katabasis/Katabasis4.jpg",
        ]
      : ["katabasis/Katabasis5.jpg", "katabasis/Katabasis10.jpg"];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var slideList = picSlideshowList.map((imgSource, i) => {
    return (
      <div key={i}>
        <div className="containerSlide">
          <img
            className="slide"
            aria-label="squillace 1923 luxe glove collection"
            src={`${imgSource}`}
            alt="gloves campaign squillace 1923"
          />
        </div>
      </div>
    );
  });

  return <Slide {...proprietes}>{slideList}</Slide>;
};

export default Slideshow;
