import CampaignZoom from "../../Components/CampaignZoom";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { campaignCielo } from "../../Utils/CampaignDefinitions";

const Cielo = () => {
  const [fullScreenPicture, setFullScreenPicture] = useState<
    string | undefined
  >(undefined);
  const picList = [
    "/campaign/Cielo1.jpg",
    "/campaign/Cielo2.jpg",
    "/campaign/Cielo3.jpg",
    "/campaign/Cielo4.jpg",
    "/campaign/Cielo5.jpg",
    "/campaign/Cielo6.jpg",
    "/campaign/Cielo7.jpg",
    "/campaign/Cielo8.jpg",
    "/campaign/Cielo9.jpg",
    "/campaign/Cielo10.jpg",
    "/campaign/Cielo11.jpg",
    "/campaign/Cielo12.jpg",
    "/campaign/Cielo13.jpg",
    "/campaign/Cielo14.jpg",
    "/campaign/Cielo15.jpg",
  ];
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    window.scrollTo(0, scrollPosition);
  };

  const renderVerticalRange = (range: string[]) => (
    <div className="campaignPicRange pointer">
      {range.map((picture, key) => (
        <img
          key={key}
          aria-label="Campaign Cielo squillace 1923 leather gloves"
          className="campaignPicVertical"
          src={`/campaign/${picture}`}
          alt={`campaign ${campaignCielo.title} squillace1923 gloves`}
          onClick={() => {
            setScrollPosition(window.pageYOffset);
            setFullScreenPicture(`/campaign/${picture}`);
          }}
        />
      ))}
    </div>
  );

  const renderHorizontalRange = (range: string) => (
    <div className="campaignPicRange">
      <img
        key={3}
        aria-label="Campaign Cielo squillace 1923 leather gloves"
        className="campaignPicHorizontal pointer"
        src={`/campaign/${range}`}
        alt={`campaign ${campaignCielo.title} squillace1923 gloves`}
        onClick={() => {
          setScrollPosition(window.pageYOffset);
          setFullScreenPicture(`/campaign/${range}`);
        }}
      />
    </div>
  );
  const ranges = campaignCielo.pictureRanges;

  return (
    <div className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <title>
          Squillace 1923 - Luxury leather gloves campaign handmade in Italy -
          Cielo season 21
        </title>

        <meta
          name="description"
          content="Squillace 1923 is the Haute Couture brand by Omega srl. 
    Luxurious and elegant leather gloves collections, made in Naples following the ancestral phases of production, entirely handmade."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="title">
        <h1>{campaignCielo.title.toUpperCase()}</h1>
        <h2>{`${campaignCielo.description[0].toUpperCase()}${campaignCielo.description
          .slice(1)
          .toLowerCase()}`}</h2>
      </div>
      <div className="campaignPic">
        {renderVerticalRange(ranges.firstRange)}
        {renderHorizontalRange(ranges.secondRange)}
        {renderVerticalRange(ranges.thirdRange)}
        {renderHorizontalRange(ranges.fourthRange)}
        {renderVerticalRange(ranges.fifthRange)}
        {renderHorizontalRange(ranges.sixthRange)}
        {renderVerticalRange(ranges.seventhRange)}
      </div>

      {fullScreenPicture && (
        <CampaignZoom
          picList={picList}
          fullScreenPicture={fullScreenPicture}
          setFullScreenPicture={setFullScreenPicture}
          handleScroll={handleScroll}
        />
      )}
    </div>
  );
};

export default Cielo;
