import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <title>
          Squillace 1923 - Luxury leather gloves brand handmade in Naples
        </title>

        <link rel="canonical" href="https://www.squillace1923.com/about" />
        <meta
          name="description"
          content="Squillace 1923 is the Haute Couture brand by Omega srl. 
    Luxurious and elegant leather gloves collections, made in Naples following the ancestral phases of production, entirely handmade."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div
        className="title"
        role="region"
        aria-label="about squillace1923 brand"
      >
        <h1>ABOUT</h1>
        <h2>Heritage and passion for exceptional glove making since 1923</h2>
      </div>
      <div className="about">
        <div className="about-first-row">
          <img
            className="about-main-pic"
            aria-label="About squillace 1923 omega srl"
            src="/about/about1.jpg"
            alt="about gloves leather squillace1923"
          />
          <div className="about-bloc">
            <p className="about-text">
              Naples is the acknowledged Capital of Gloves since the XVII
              century when the Bourbon dynasty encouraged the artisanal
              production in the Kingdom of the Two Sicilies. <br></br>
              It was right in the heart of the city, the Sanità district, where
              entire families specialised in the production of handmade gloves.
              The art of making leather gloves is a family tradition, with the
              skill and knowledge handed down from one generation to the other.{" "}
              <br></br>
              <br></br>
              Gennaro Squillace founded in 1923 the OMEGA Company, the only
              traditional glove-making business still operating in the Sanità
              district for almost over a century. Five generations later,
              Alberto Squillace is leading the family business into the future
              still honouring the traditional handicraft way of the Neapolitan
              glove confection. <br></br>
              <b> SQUILLACE 1923</b> is the Haute Couture Brand founded by the
              Maison Omega and led by Martina Squillace, the great-granddaughter
              of Gennaro Squillace and Alberto’s sister.<br></br>
              <br></br>
              Motivated by the passion for design and for the highest attention
              to details, our activity is mainly focused on creating bespoke
              experiences combined with the Squillace Family Heritage. <br></br>
              Haute Couture gloves by SQUILLACE 1923 are limited edition items
              anchored to the sustainability development philosophy of the
              brand. <br></br>
              Our creations are made with the finest leather and lined with pure
              silk and cashmere from Biella, providing our clients with the
              highest quality of the Made in Italy.
            </p>
            <img
              className="about-second-pic"
              aria-label="About squillace 1923 omega srl"
              src="/about/Stamp_squillace1923.jpg"
              alt="about gloves leather squillace1923"
            />
          </div>
        </div>
        <div className="about-row">
          <img
            aria-label="About squillace 1923 omega srl"
            className="about-pic"
            src="/about/about2.jpg"
            alt="about gloves leather squillace1923"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
