import React, { useState, useEffect, useRef, useCallback } from "react";
// @ts-ignore
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faTimes, // @ts-ignore
} from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import { useSwipeable } from "react-swipeable";

interface CampaignZoomProps {
  // collectionToShowID: number;
  picList: string[];
  fullScreenPicture: string;
  setFullScreenPicture: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  handleScroll: () => void;
}
const CampaignZoom = ({
  // collectionToShowID,
  picList,
  setFullScreenPicture,
  fullScreenPicture,
  handleScroll,
}: CampaignZoomProps) => {
  const [hideArrow, sethideArrow] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const prevRef = useRef<number>(0);
  useEffect(() => {
    prevRef.current = count;
  });

  const handlers = useSwipeable({
    onSwiped: (eventData) => (eventData.deltaX > 0 ? goBack() : goNext()),
  });

  const handleUserKeyPress = useCallback(
    (event) => {
      const { key } = event;
      if (key === "ArrowRight") {
        if (prevRef.current < picList.length - 1) {
          setCount(prevRef.current + 1);
        } else {
          setCount(0);
        }
      } else if (key === "ArrowLeft") {
        if (prevRef.current !== 0) {
          setCount(prevRef.current - 1);
        } else {
          setCount(picList.length - 1);
        }
      } else if (key === "Escape") {
        setFullScreenPicture(undefined);
      }
    },
    [setFullScreenPicture, setCount, picList]
  );

  useEffect(() => {
    picList.map((picture, index) => {
      if (picture === fullScreenPicture) {
        setCount(index);
      }
      return picture;
    });

    if (picList.length === 1) {
      sethideArrow(true);
    }
    document.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress, fullScreenPicture, picList]);

  const goNext = () => {
    if (count < picList.length - 1) {
      setCount(count + 1);
    } else {
      setCount(0);
    }
  };
  const goBack = () => {
    if (count !== 0) {
      setCount(count - 1);
    } else {
      setCount(picList.length - 1);
    }
  };

  return (
    <div
      className="popUpCampaignPicture"
      onClick={(e) => {
        e.stopPropagation();
        handleScroll();
        setFullScreenPicture(undefined);
      }}
    >
      <div className="fullScreenCampaignPic">
        <div className="fullScreenCampaignPic-content">
          {!hideArrow && (
            <FontAwesomeIcon
              className="campaignArrow"
              icon={faChevronLeft}
              onClick={(e) => {
                e.stopPropagation();
                goBack();
              }}
            />
          )}
          <img
            {...handlers}
            className="slideCampaignCollec"
            aria-label="Campaign Cielo squillace 1923"
            src={picList[count]}
            alt={`campaign squillace1923 gloves`}
            onClick={(e) => {
              e.stopPropagation();
              goNext();
            }}
          />

          {!hideArrow && (
            <FontAwesomeIcon
              className="campaignArrow"
              icon={faChevronRight}
              onClick={(e) => {
                e.stopPropagation();
                goNext();
              }}
            />
          )}
          <FontAwesomeIcon
            className="closeCampaign"
            icon={faTimes}
            onClick={(e) => {
              e.stopPropagation();
              handleScroll();
              setFullScreenPicture(undefined);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignZoom;
