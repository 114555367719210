import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Campaign = () => {
  const campaigns = [
    {
      name: "Katabasis",
      path: "/katabasis",
      url: "katabasis/Katabasis9.jpg",
    },
    {
      name: "Lost Angeles",
      path: "/lostangeles",
      url: "lostangeles/LostAngeles13.jpg",
    },
    {
      name: "Doopelgänger",
      path: "/doppelganger",
      url: "doppelganger/Doppelganger1.jpg",
    },
    { name: "Cielo", path: "/cielo", url: "campaign/Cielo1.jpg" },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const campaignsCard = campaigns.map((campaign, key) => (
    <Link key={key} className="campaignCard" to={`/campaign${campaign.path}`}>
      <img
        className="campaign-logo"
        src={campaign.url}
        alt="squillace1923-campaigns"
      />
      <div className="campaignCard-link">
        <h1 className="campaign-link">{campaign.name.toUpperCase()}</h1>
      </div>
    </Link>
  ));

  //   const array = [1, 2];
  //   const skeletons = array.map((element, key) => (
  //     <div className="campaignCard" key={key}>
  //       <Skeleton
  //         className="campaign-logo"
  //         count={1}
  //         color="#202020"
  //         highlightColor="#444"
  //       />
  //     </div>
  //   ));

  return (
    <div className="content">
      <div className="title" style={{ marginBottom: "50px" }}>
        <h1>CAMPAIGN</h1>
      </div>

      <div className="campaign-page">{campaignsCard}</div>
    </div>
  );
};

export default Campaign;
