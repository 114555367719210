import React, { useState, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Redirect } from "react-router";
import ErrorMessage from "../Components/ErrorMessage";
import { Helmet } from "react-helmet";

interface ContactForm {
  name: string;
  email: string;
  message: string;
}
const Contact = () => {
  const [form, setform] = useState<ContactForm>({
    name: "",
    email: "",
    message: "",
  });

  const [isMessageSent, setIsMessageSent] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  var handleForm = (e: any) => {
    e.preventDefault();
    fetch("/api/contact", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: `name=${form.name}&email=${form.email}&message=${form.message}`,
    })
      .then((response) => response.json())
      .then((data) => {
        data.hasSentMessage && setIsMessageSent(true);
      })
      .catch((error) => error && setError(error));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return isMessageSent ? (
    <Redirect to="/confirmation-request" />
  ) : (
    <div className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <title>Squillace 1923 - Contact Us</title>
        <meta
          name="description"
          content="Squillace 1923 is the Haute Couture brand by Omega srl. 
    Luxurious and elegant leather gloves collections, made in Naples following the ancestral phases of production, entirely handmade."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="title">
        <h1>CONTACT</h1>
      </div>
      <div className="contact">
        <img
          aria-label="Contact squillace 1923 lamb leather"
          className="contactPic"
          src="/contact/contact.jpg"
          alt="contact squillace buy gloves"
        />
        <form
          aria-label="Contact information"
          className="contact-bloc"
          onSubmit={(e) => handleForm(e)}
        >
          <div className="contact-form">
            <div className="contact-row">
              <label>Name</label>
              <input
                className="inputForm"
                type="text"
                name="name"
                onChange={(e) => setform({ ...form, name: e.target.value })}
                required
              ></input>
            </div>
            <div className="contact-row">
              <label>Email</label>
              <input
                className="inputForm"
                type="email"
                name="email"
                onChange={(e) => setform({ ...form, email: e.target.value })}
                required
              ></input>
            </div>
            <div className="contact-row">
              <label>Message</label>
              <TextareaAutosize
                name="message"
                className="inputForm"
                id="textarea"
                onChange={(e) => setform({ ...form, message: e.target.value })}
                required
                style={{
                  padding: "10px 15px",
                  marginTop: "0px",
                  marginBottom: "30px",
                }}
              />
            </div>
            {error && <ErrorMessage />}
            <button className="contactButton" type="submit">
              Send
            </button>
          </div>
          <div className="contactInfo">
            <h5>
              Contact : <br></br>info@squillace1923.com
            </h5>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
