import React, { useEffect } from "react";
import Footer from "../Components/Footer";
import useLocalStorage from "../Utils/useLocalStorage";
import { Helmet } from "react-helmet";

export interface CountProps {
  setCount: React.Dispatch<React.SetStateAction<number>>;
}

const ThanksForOrder = ({ setCount }: CountProps) => {
  const [wishlist] = useLocalStorage("wishlist-doppelganger", []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCount(wishlist.length);
  }, [setCount, wishlist]);

  return (
    <div className="content confirm">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <title>
          Squillace 1923 - Luxury leather gloves brand handmade in Italy
        </title>

        <meta
          name="description"
          content="Squillace 1923 is the Haute Couture brand by Omega srl. 
    Luxurious and elegant leather gloves collections, made in Naples following the ancestral phases of production, entirely handmade."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <h1 className="title">
        {"Your request has been sent".toLocaleUpperCase()}
      </h1>
      <h2 className="confirmation-content" style={{ color: "black" }}>
        Thank you for contacting us. We will get back to you as soon as possible
        with the information about your request.
      </h2>
      <img
        aria-label="squillace 1923 Cielo glove collection"
        className="confirmation-image"
        src="/campaign/Cielo8.jpg"
        alt={`campaign squillace1923 gloves`}
      />
      <Footer />
    </div>
  );
};

export default ThanksForOrder;
