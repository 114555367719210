import React, { useState } from "react";
// @ts-ignore
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import ErrorMessage from "./ErrorMessage";
// @ts-ignore
import Loader from "react-js-loader";
import { Client, Order, ProductWithSizeAndColor } from "../Containers/Wishlist";

interface OrderSummaryProps {
  order: Order;
  products: ProductWithSizeAndColor[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  client: Client;
}

const OrderSummary = ({
  order,
  products,
  setIsOpen,
  client,
}: OrderSummaryProps) => {
  const [isOrderSent, setIsOrderSent] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [isloading, setIsLoading] = useState<boolean>(false);

  const sendRequest = async () => {
    setIsLoading(true);
    const url = "/api/send-request";
    await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: `orderId=${order._id}`,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.orderValidation) {
          window.localStorage.removeItem("wishlist-doppelganger");
          setIsOrderSent(true);
        }
      })
      .catch((error) => error && setError(true));
  };

  const productCards = products.map((product, key) => {
    return (
      <div key={key} className="summary-card">
        <div className="summary-card-left">
          <img
            className="summary-image"
            aria-label="squillace 1923 gloves design products"
            src={
              product.images.find((image) => image.color === product.color)
                ?.thumbnailUrl ?? ""
            }
            alt={`campaign ${product.name} squillace1923 gloves`}
          />
          <div className="summary-sub-info">
            <p style={{ margin: 0 }}>{product.name}</p>
            <h5 style={{ margin: 0 }}>color : {product.color}</h5>
          </div>
        </div>
        <p style={{ margin: 0 }}>Size : {product.size}</p>
        <h5>{product.price} €</h5>
      </div>
    );
  });

  return isOrderSent ? (
    <Redirect to="/confirmation-request" />
  ) : (
    <div
      className="popIn"
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(false);
      }}
    >
      {!isloading ? (
        <div className="summary-content" onClick={(e) => e.stopPropagation()}>
          <div className="closePopIn">
            <FontAwesomeIcon
              className="fal closePopInIcone dark"
              icon={faTimes}
              onClick={() => setIsOpen(false)}
            />
          </div>
          <h4>Your Order</h4>
          <div className="summary-product">
            {productCards}
            <div className="summary-card">
              <h5 className="total">Total</h5>
              <h4 className="total">{order.totalPrice} €</h4>
            </div>
          </div>

          <div className="summary-contact">
            <div className="summary-info">
              <h4>Contact Info</h4>
              <p style={{ margin: 0 }}>
                {client.lastName} {client.firstName}
                <br></br>
                {client.email}
                {client.phone ? (
                  <>
                    {" "}
                    <br></br>
                    {client.phone}
                  </>
                ) : null}
              </p>
            </div>

            <button onClick={() => sendRequest()}>Send Request</button>
          </div>
          {error && <ErrorMessage />}
        </div>
      ) : (
        <div className="loader">
          <Loader
            type="spinner-default"
            bgColor={"white"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
