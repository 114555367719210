import FullScreenPicture from "../Components/FullScreenPicture";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as fasStar } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import useLocalStorage from "../Utils/useLocalStorage";
import ErrorMessage from "../Components/ErrorMessage";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import { CountProps } from "./ThanksForOrder";
import { ProductToOrder } from "./Wishlist";

export interface Image {
  thumbnailUrl: string;
  url: string;
  color: string;
}
export interface Product {
  _id: string;
  name: string;
  description: string;
  images: Image[];
  price: number;
  fabric: string;
  lining?: string | null;
  length?: string | null;
  sizeRange: string[];
  colorRange: { name?: string | null; code?: string | null }[];
  collectionName: string;
  zindex?: number | null;
}

const Collection = ({ setCount }: CountProps) => {
  const [fullScreenPicture, setFullScreenPicture] = useState<string | null>(
    null
  );
  const [fullScreenProduct, setFullScreenProduct] = useState<Product | null>(
    null
  );
  const [error, setError] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [productsData, setProductsData] = useState<Product[]>([]);
  const [wishlist, setWishList] = useLocalStorage("wishlist-doppelganger", []);

  const addToWishList = (wishlistProduct: ProductToOrder) => {
    if (isInWishList(wishlistProduct.id)) {
      const newWishlist = (wishlist as ProductToOrder[]).filter(
        (product, index) => (product.id === wishlistProduct.id ? false : true)
      );
      setWishList(newWishlist);
      setCount(newWishlist.length);
    } else {
      setCount(wishlist.length + 1);
      setWishList([...wishlist, wishlistProduct]);
    }
  };

  const addProduct = (product: ProductToOrder) => {
    if (isInWishListWithoutSize(product.id, product.color ?? "")) {
      const newWishlist = (wishlist as ProductToOrder[]).map(
        (wishlistProduct) =>
          wishlistProduct.id === product.id &&
          wishlistProduct.color === product.color &&
          wishlistProduct.size === null
            ? product
            : wishlistProduct
      );
      setWishList(newWishlist);
      setCount(newWishlist.length);
    } else {
      setCount(wishlist.length + 1);
      setWishList([...wishlist, product]);
    }
  };

  const isInWishList = (productId: string) =>
    !(wishlist as ProductToOrder[]).every(
      (product) => product.id !== productId
    );

  const isInWishListWithoutSize = (productId: string, color: string) =>
    (wishlist as ProductToOrder[]).some(
      (product) =>
        product.id === productId &&
        color === product.color &&
        product.size === null
    );

  useEffect(() => {
    window.scrollTo(0, 0);
    const url = "/api/products";

    const getProducts = async () =>
      await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setProductsData(data.products);
        })
        .catch((error) => error && setError(error));
    getProducts();
  }, []);

  const handleProductClick = (product: Product) => {
    setFullScreenProduct(product);
    setFullScreenPicture(product.images[0].url);
    setScrollPosition(window.pageYOffset);
  };
  const handleScroll = () => {
    window.scrollTo(0, scrollPosition);
  };
  const productsCard = productsData.map((product, key) => (
    <div
      key={key}
      className="collectionCard"
      onClick={() => handleProductClick(product)}
    >
      <div className="collection-wishlistIconCard">
        <FontAwesomeIcon
          icon={isInWishList(product._id) ? fasStar : faStar}
          className="collection-wishlistIcon"
          onClick={(e) => {
            e.stopPropagation();
            addToWishList({
              id: product._id,
              size: undefined,
              color: product.colorRange[0].name ?? undefined,
            });
          }}
        />
      </div>

      <img
        key={key}
        aria-label="squillace 1923 gloves design products"
        className="collectionPicture"
        src={product.images[0].url}
        alt={`campaign ${product.name} squillace1923 gloves`}
      />

      <h4
        role="region"
        aria-label="products squillace1923 gloves"
        style={{ margin: 0 }}
      >
        {product.name}
      </h4>
      <h5>{product.price} €</h5>
    </div>
  ));

  const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const skeletons = array.map((element, key) => (
    <div className="collectionCard" key={key}>
      <div className="collection-wishlistIconCard">
        <FontAwesomeIcon icon={faStar} className="collection-wishlistIcon" />
      </div>
      {/* @ts-ignore */}
      <Skeleton count={1} color="#202020" highlightColor="#444" />
      <h4 style={{ margin: 0 }}>
        {/* @ts-ignore */}
        <Skeleton count={1} color="#202020" highlightColor="#444" />
      </h4>
      <h5>
        {/* @ts-ignore */}
        <Skeleton count={1} color="#202020" highlightColor="#444" />
      </h5>
    </div>
  ));

  return (
    <div className="content collection">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <title>
          Squillace 1923 - Luxury leather gloves collection handmade in Italy
        </title>

        <meta
          name="description"
          content="Squillace 1923 is the Haute Couture brand by Omega srl. 
    Luxurious and elegant leather gloves collections, made in Naples following the ancestral phases of production, entirely handmade."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="title">
        <h1>COLLECTION</h1>
        <h2>
          Our collection is unique and timeless. It proposes classic styles that
          you can find in different seasonal colours and limited edition
          presented in our conceptual campaign.
        </h2>
        {error && <ErrorMessage />}
      </div>
      <div className="collectionRow">
        {productsData.length ? productsCard : skeletons}
      </div>

      {fullScreenPicture && fullScreenProduct?.images && (
        <FullScreenPicture
          picList={fullScreenProduct.images.map((image) => image.url)}
          fullScreenPicture={fullScreenPicture}
          setFullScreenPicture={setFullScreenPicture}
          details={fullScreenProduct}
          addProduct={addProduct}
          handleScroll={handleScroll}
        />
      )}
    </div>
  );
};

export default Collection;
