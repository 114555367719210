import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import SelectInput from "./SelectInput";
import { Product } from "../Containers/Collection";
import { ProductToOrder } from "../Containers/Wishlist";

export interface WishlistProduct {
  id: string;
  color: string;
  size?: string;
  quantity?: number;
}
interface FullScreenDetailProps {
  details: Product;
  addProduct: (product: ProductToOrder) => void;
  close: React.Dispatch<React.SetStateAction<string | null>>;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  count: number;
}
const FullScreenDetail = ({
  details,
  addProduct,
  close,
  setCount,
  count,
}: FullScreenDetailProps) => {
  const [fillSize, setFillSize] = useState<boolean>(false);

  const [size, setSize] = useState<string | null>(null);
  const [color, setColor] = useState<string>(details.images[count].color);

  const handleSizeChange = (size: string) => {
    setSize(size);
  };

  const handleAddToWishlist = () => {
    if (size) {
      addProduct({ id: details._id, size, color });
      close(null);
    } else {
      setFillSize(true);
    }
  };
  const isColorWhite = (name: string) => {
    return name?.split(" ")[0] === "white";
  };

  const handleColorChange = (color: string) => {
    const index = details.images.findIndex((image) => image.color === color);
    setColor(color);
    setCount(index);
  };

  useEffect(() => {
    setColor(details.images[count].color);
  }, [count, details.images]);

  return (
    <div className="fullScreenDetails">
      <h1>
        <b>{details.name.toUpperCase()}</b>
      </h1>
      <h2>{details.description}</h2>
      <h1>
        <b>{details.price} €</b>
      </h1>
      <h2>Color : {details.images[count].color}</h2>
      <div className="colorRange">
        {details.colorRange.map((productColor, key) => {
          return (
            <div
              key={key}
              className="rounded-color"
              style={{
                //@ts-ignore
                backgroundColor: productColor.code,
                border:
                  productColor.name === color
                    ? "2px solid #595959"
                    : isColorWhite(productColor.name ?? "")
                    ? "1px solid black"
                    : "none",
              }}
              onClick={() => handleColorChange(productColor.name ?? "")}
            ></div>
          );
        })}
      </div>
      <div style={{ marginTop: "10px", width: "50%" }}>
        <SelectInput
          size={size}
          handleSizeChange={handleSizeChange}
          sizeRange={details.sizeRange}
          productId={details._id}
        />
      </div>
      {fillSize && (
        <h6 className="caption" style={{ color: "red", margin: 0 }}>
          * Please select your size
        </h6>
      )}
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleAddToWishlist();
        }}
      >
        ADD TO WISHLIST{" "}
        <FontAwesomeIcon icon={faStar} style={{ paddingLeft: "10px" }} />
      </button>
    </div>
  );
};

export default FullScreenDetail;
