import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface HowToChooseSizeProps {
  setIsHowToChooseSizeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const HowToChooseSize = ({
  setIsHowToChooseSizeOpen,
}: HowToChooseSizeProps) => {
  return (
    <div
      className="popIn"
      onClick={(e) => {
        e.stopPropagation();
        setIsHowToChooseSizeOpen(false);
      }}
    >
      <div className="popIn-content" onClick={(e) => e.stopPropagation()}>
        <div className="closePopIn">
          <FontAwesomeIcon
            className="closePopInIcone dark"
            icon={faTimes}
            onClick={() => setIsHowToChooseSizeOpen(false)}
          />
        </div>
        <img
          className="measurementPic"
          aria-label="measurement glove size guide"
          src={"/measurement/gloves.jpg"}
          alt={`Size how to mesure gloves`}
        />
        <h2 className="measurementText">
          To find out your glove size you need to wrap the measuring tape around
          your palm, just below the knuckles, not including the thumb and
          divided the circumference by 2.7. Glove size should be measured in cm.
          <br></br>
          <br></br>
          E.g. 18,2 : 2,7 = 6,7 Your size will be 6.5
        </h2>
        <img
          className="measurementTable"
          aria-label="measurement size guide"
          src={"/measurement/gloves 2.jpg"}
          alt={`Size how to mesure gloves`}
        />
      </div>
    </div>
  );
};

export default HowToChooseSize;
