import CampaignZoom from "../../Components/CampaignZoom";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { campaignLostAngeles } from "../../Utils/CampaignDefinitions";

const LostAngeles = () => {
  const [fullScreenPicture, setFullScreenPicture] = useState<
    string | undefined
  >(undefined);
  const picList = [
    "/lostangeles/LostAngeles0.jpg",
    "/lostangeles/LostAngeles2.jpg",
    "/lostangeles/LostAngeles3.jpg",
    "/lostangeles/LostAngeles4.jpg",
    "/lostangeles/LostAngeles5.jpg",
    "/lostangeles/LostAngeles6.jpg",
    "/lostangeles/LostAngeles7.jpg",
    "/lostangeles/LostAngeles8.jpg",
    "/lostangeles/LostAngeles9.jpg",
    "/lostangeles/LostAngeles10.jpg",
    "/lostangeles/LostAngeles11.jpg",
    "/lostangeles/LostAngeles12.jpg",
    "/lostangeles/LostAngeles13.jpg",
    "/lostangeles/LostAngeles14.jpg",
    "/lostangeles/LostAngeles15.jpg",
    "/lostangeles/LostAngeles16.jpg",
    "/lostangeles/LostAngeles17.jpg",
    "/lostangeles/LostAngeles18.jpg",
  ];
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    window.scrollTo(0, scrollPosition);
  };

  const renderVerticalRange = (range: string[]) => (
    <div className="campaignPicRange pointer">
      {range.map((picture, key) => (
        <img
          key={key}
          aria-label="Campaign DoppelGanger squillace 1923 leather gloves"
          className="campaignPicVertical"
          src={`/lostangeles/${picture}`}
          alt={`lostangeles ${campaignLostAngeles.title} squillace1923 gloves`}
          onClick={() => {
            setScrollPosition(window.pageYOffset);
            setFullScreenPicture(`/lostangeles/${picture}`);
          }}
        />
      ))}
    </div>
  );

  const renderHorizontalRange = (range: string) => (
    <div className="campaignPicRange">
      <img
        key={3}
        aria-label="Campaign DoppelGanger squillace 1923 leather gloves"
        className="campaignPicHorizontal pointer"
        src={`/lostangeles/${range}`}
        alt={`lostangeles ${campaignLostAngeles.title} squillace1923 gloves`}
        onClick={() => {
          setScrollPosition(window.pageYOffset);
          setFullScreenPicture(`/lostangeles/${range}`);
        }}
      />
    </div>
  );
  const ranges = campaignLostAngeles.pictureRanges;

  const description = campaignLostAngeles.description.split(".");

  return (
    <div className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <title>
          Squillace 1923 - Luxury leather gloves campaign handmade in Italy -
          Lost Angeles season 22
        </title>

        <meta
          name="description"
          content="Squillace 1923 is the Haute Couture brand by Omega srl. 
    Luxurious and elegant leather gloves collections, made in Naples following the ancestral phases of production, entirely handmade."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="title">
        <h1>{campaignLostAngeles.title.toUpperCase()}</h1>
        <h2>
          {description.map((text, index) => (
            <>
              {text + "."}
              <br></br>
            </>
          ))}
        </h2>
      </div>
      <div className="campaignPic">
        {renderHorizontalRange(ranges.firstRange)}
        {renderVerticalRange(ranges.secondRange)}
        {renderHorizontalRange(ranges.thirdRange)}
        {renderVerticalRange(ranges.fourthRange)}
        {renderHorizontalRange(ranges.fifthRange)}
        {renderVerticalRange(ranges.sixthRange)}
        {renderVerticalRange(ranges.seventhRange)}
        {renderHorizontalRange(ranges.eightRange)}
        {renderVerticalRange(ranges.ninethRange)}
      </div>

      {fullScreenPicture && (
        <CampaignZoom
          picList={picList}
          fullScreenPicture={fullScreenPicture}
          setFullScreenPicture={setFullScreenPicture}
          handleScroll={handleScroll}
        />
      )}
    </div>
  );
};

export default LostAngeles;
