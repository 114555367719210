import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Links, { navLinks } from "./Links";

export interface HeaderProps {
  isHome: boolean;
  setIsHome: React.Dispatch<React.SetStateAction<boolean>>;
  count: number;
}
const Header = ({ isHome, setIsHome, count }: HeaderProps) => {
  const [activeLink, setActiveLink] = useState(navLinks.HOME);

  useEffect(() => {
    if (isHome) {
      setActiveLink(navLinks.HOME);
    } else {
      const url = window.location.href.split("/");
      setActiveLink(url[3].toUpperCase() as navLinks);
    }
  }, [activeLink, isHome]);

  const handleLinks = (link: navLinks) => {
    if (link === navLinks.HOME) {
      setIsHome(true);
    } else {
      setActiveLink(link);
      setIsHome(false);
    }
  };

  return (
    <header className={isHome ? "header-home" : "header"}>
      <div className={isHome ? "logo-home" : "logo"}>
        <Link to="/" onClick={() => handleLinks(navLinks.HOME)}>
          <img
            aria-label="squillace 1923 logo"
            className={isHome ? "logo-image-home" : "logo-image"}
            src={isHome ? "/squillace1923.jpg" : "/logo_squillace_stella.png"}
            alt="logo Squillace 1923"
          />
        </Link>
      </div>

      <nav className={isHome ? "nav-links-home" : "nav-links"}>
        <Links
          handleLinks={handleLinks}
          count={count}
          activeLink={activeLink}
        />
      </nav>
    </header>
  );
};

export default Header;
