import React, { useState, useEffect, useRef, useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import FullScreenDetail from "./FullScreenDetails";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { useSwipeable } from "react-swipeable";
import { Product } from "../Containers/Collection";
import { ProductToOrder } from "../Containers/Wishlist";

export interface FullScreenPictureProps {
  picList: string[];
  fullScreenPicture: string;
  setFullScreenPicture: React.Dispatch<React.SetStateAction<string | null>>;
  details: Product;
  addProduct: (product: ProductToOrder) => void;
  handleScroll: () => void;
}

const FullScreenPicture = ({
  picList,
  fullScreenPicture,
  setFullScreenPicture,
  details,
  addProduct,
  handleScroll,
}: FullScreenPictureProps) => {
  const [hideArrow, sethideArrow] = useState(false);
  const [count, setCount] = useState(0);
  const { innerWidth: width } = window;
  const handlers = useSwipeable({
    onSwiped: (eventData) => (eventData.deltaX > 0 ? goBack() : goNext()),
  });
  const prevRef = useRef<number>(0);
  useEffect(() => {
    prevRef.current = count;
  });

  const handleUserKeyPress = useCallback(
    (event) => {
      const { key } = event;
      if (key === "ArrowRight") {
        if (prevRef.current < picList.length - 1) {
          setCount(prevRef.current + 1);
        } else {
          setCount(0);
        }
      } else if (key === "ArrowLeft") {
        if (prevRef.current !== 0) {
          setCount(prevRef.current - 1);
        } else {
          setCount(picList.length - 1);
        }
      } else if (key === "Escape") {
        setFullScreenPicture(null);
      }
    },
    [setFullScreenPicture, setCount, picList]
  );

  const isSmallScreen = width < 850;

  useEffect(() => {
    picList.map((picture, index) => {
      if (picture === fullScreenPicture) {
        setCount(index);
      }
      return picture;
    });

    if (isSmallScreen) {
      sethideArrow(true);
    }
    if (picList.length === 1) {
      sethideArrow(true);
    }
    document.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress, fullScreenPicture, picList, isSmallScreen]);

  const goNext = () => {
    if (count < picList.length - 1) {
      setCount(count + 1);
    } else {
      setCount(0);
    }
  };
  const goBack = () => {
    if (count !== 0) {
      setCount(count - 1);
    } else {
      setCount(picList.length - 1);
    }
  };

  return (
    <div
      className="popUpPicture"
      onClick={(e) => {
        e.stopPropagation();
        handleScroll();
        setFullScreenPicture(null);
      }}
    >
      <div
        className="fullScreenPic light-background"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="fullScreenPic-content">
          {!hideArrow && (
            <FontAwesomeIcon
              className="leftIconeSmall"
              icon={faChevronLeft}
              onClick={() => goBack()}
            />
          )}
          {!isSmallScreen ? (
            <div className="zoom-small">
              <InnerImageZoom
                className="slidecollec"
                src={picList[count]}
                zoomSrc={picList[count]}
                alt={`campaign squillace1923 gloves`}
                zoomPreload={true}
                zoomType="hover"
                hideHint={true}
              />
            </div>
          ) : (
            <img
              className="slidecollec"
              aria-label="products detail"
              alt={`campaign squillace1923 gloves`}
              src={picList[count]}
              onClick={() => goNext()}
              {...handlers}
            />
          )}

          {!hideArrow && (
            <FontAwesomeIcon
              className="rightIconeSmall"
              icon={faChevronRight}
              onClick={() => goNext()}
            />
          )}
          <FullScreenDetail
            details={details}
            close={setFullScreenPicture}
            addProduct={addProduct}
            count={count}
            setCount={setCount}
          />
        </div>
        <FontAwesomeIcon
          className="closeIcone dark"
          icon={faTimes}
          onClick={() => {
            setFullScreenPicture(null);
            handleScroll();
          }}
        />
      </div>
    </div>
  );
};
export default FullScreenPicture;
