import { Client } from "../Containers/Wishlist";
import React, { useState } from "react";

interface ClientFormProps {
  sendRequest: (e: React.FormEvent<HTMLFormElement>, client: Client) => void;
}
const ClientForm = ({ sendRequest }: ClientFormProps) => {
  const [client, setClient] = useState<Client>({
    firstName: "",
    lastName: "",
    email: "",
    phone: undefined,
    newsletter: false,
  });

  return (
    <form
      aria-label="Client information"
      className="clientForm"
      onSubmit={(e) => sendRequest(e, client)}
    >
      <div className="clientFormInput">
        <input
          className="pureInput"
          type="text"
          id="firstName"
          name="firstName"
          placeholder="First Name *"
          value={client.firstName}
          onChange={(e) => setClient({ ...client, firstName: e.target.value })}
          required
        />
        <input
          className="pureInput"
          type="text"
          id="lastName"
          name="lastName"
          placeholder="Last Name *"
          value={client.lastName}
          onChange={(e) => setClient({ ...client, lastName: e.target.value })}
          required
        />
      </div>
      <div className="clientFormInput">
        <input
          className="pureInput"
          type="text"
          id="email"
          name="email"
          placeholder="Email *"
          value={client.email}
          onChange={(e) => setClient({ ...client, email: e.target.value })}
          required
        />
        <input
          className="pureInput"
          type="text"
          id="phone"
          name="phone"
          placeholder="Phone"
          value={client.phone}
          onChange={(e) => setClient({ ...client, phone: e.target.value })}
        />
      </div>
      <div className="clientFormCheckbox">
        <input
          type="checkbox"
          id="newsletter"
          name="newsletter"
          checked={client.newsletter}
          onChange={(e) =>
            setClient({ ...client, newsletter: !client.newsletter })
          }
        />
        <label htmlFor="newsletter" id="newsletter">
          Subscribe to the newsletter
        </label>
      </div>
      <button className="clientFormButton" type="submit">
        SEND REQUEST
      </button>
    </form>
  );
};

export default ClientForm;
