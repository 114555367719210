import React, { useState } from "react";
import HowToChooseSize from "./HowToChooseSize";
//@ts-ignore
import Select from "react-select";

interface Options {
  value: string;
  label: string;
}
interface SelectInputProps {
  size: string | null;
  handleSizeChange: (size: string, productId?: string) => void;
  sizeRange?: string[] | null;
  productId: string;
}

const SelectInput = ({
  size,
  handleSizeChange,
  sizeRange,
  productId,
}: SelectInputProps) => {
  const [isHowToChooseSizeOpen, setIsHowToChooseSizeOpen] = useState<boolean>(
    false
  );

  const options: Options[] =
    sizeRange?.map((size) => ({
      value: size,
      label: size,
    })) ?? [];

  return (
    <div className="select">
      <Select
        isSearchable={false}
        options={options}
        value={size ? { value: size, label: size } : null}
        id="size"
        onChange={(option: Options) =>
          handleSizeChange(option.value, productId)
        }
        styles={{
          input: (styles: any) => ({
            ...styles,
            height: "30px",
          }),
        }}
        required
      />

      <p
        className="caption pointer hover"
        onClick={() => setIsHowToChooseSizeOpen(true)}
      >
        SIZE GUIDE
      </p>
      {isHowToChooseSizeOpen && (
        <HowToChooseSize setIsHowToChooseSizeOpen={setIsHowToChooseSizeOpen} />
      )}
    </div>
  );
};

export default SelectInput;
