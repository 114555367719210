// CIELO
export const campaignCielo = {
  title: "CIELO",
  description:
    "A TRIBUTE dedicated to the empowerment of a modern, sophisticated and free woman, revealed through a reinterpretation of the metier d’art of the traditional glove making.",
  pictureRanges: {
    firstRange: ["Cielo1.jpg", "Cielo2.jpg", "Cielo3.jpg"],
    secondRange: "Cielo4.jpg",
    thirdRange: ["Cielo5.jpg", "Cielo6.jpg", "Cielo7.jpg"],
    fourthRange: "Cielo8.jpg",
    fifthRange: ["Cielo9.jpg", "Cielo10.jpg", "Cielo11.jpg"],
    sixthRange: "Cielo12.jpg",
    seventhRange: ["Cielo13.jpg", "Cielo14.jpg", "Cielo15.jpg"],
  },
};

// DOPPELGANGER
export const campaignDoppelGanger = {
  title: "Doppelgänger",
  description: `
  A doppelganger is a mysterious, exact double of a living person. It's a German word that literally translates to "double walker".
  
  The idea of evil alter ego embodies the muse of the new collection by Squillace1923.
  The alter ego is the main character of this story in which its true essence is shown.
  The collection “Doppelgänger” tells about its personality, enhancing its uniqueness and beauty hidden by a shadow of mystery that makes it impossible to resist.
  `,
  pictureRanges: {
    firstRange: "Doppelganger1.jpg",
    secondRange: [
      "Doppelganger2.jpg",
      "Doppelganger3.jpg",
      "Doppelganger4.jpg",
    ],
    thirdRange: "Doppelganger5.jpg",

    fourthRange: "Doppelganger6.jpg",
    fifthRange: ["Doppelganger7.jpg", "Doppelganger8.jpg", "Doppelganger9.jpg"],
    sixthRange: "Doppelganger10.jpg",
    seventhRange: [
      "Doppelganger11.jpg",
      "Doppelganger12.jpg",
      "Doppelganger13.jpg",
    ],
    eightRange: "Doppelganger14.jpg",
    ninethRange: [
      "Doppelganger15.jpg",
      "Doppelganger16.jpg",
      "Doppelganger17.jpg",
    ],
    tenthRange: "Doppelganger18.jpg",
  },
};

// LOSTANGELES
export const campaignLostAngeles = {
  title: "Lost Angeles",
  description: `
  Turn up that radio, Hear the rock and roll, Malibu shines like summer gold. Wild beaches in the salty wind, California summers never end ..
  `,
  pictureRanges: {
    firstRange: "LostAngeles0.jpg",
    secondRange: ["LostAngeles2.jpg", "LostAngeles3.jpg", "LostAngeles4.jpg"],
    thirdRange: "LostAngeles5.jpg",
    fourthRange: ["LostAngeles6.jpg", "LostAngeles7.jpg", "LostAngeles8.jpg"],
    fifthRange: "LostAngeles9.jpg",
    sixthRange: ["LostAngeles10.jpg", "LostAngeles11.jpg", "LostAngeles12.jpg"],
    seventhRange: [
      "LostAngeles13.jpg",
      "LostAngeles14.jpg",
      "LostAngeles15.jpg",
    ],
    eightRange: "LostAngeles16.jpg",
    ninethRange: [
      "LostAngeles17.jpg",
      "LostAngeles18.jpg",
      "LostAngeles19.jpg",
    ],
  },
};

// KATABASIS
export const campaignKatabasis = {
  title: "Katabasis",
  description: [
    `At the beginning, it was chaos. Nammu, God of the primordial seas and of all things, created the universe.
  To maintain order and preserve the beauty of the universe, he gave birth to eight gods to oversee and protect his creation. Although precious, the universe was too beautiful to keep it hidden away and Nammu decided to create something even greater: life on earth. Human beings could now admire the perfection of his universe making him proud and joyful.`,
    `Scilla and Cariddi, the guardians of the sea, together with Jeje, Baal, Mazu, Ningyo, Ran and Tiamath helped Nammu in teaching and educating humans to fully appreciate, respect, love and enjoy all the things that nature had to offer. Despite their efforts, humans turned out to be ungrateful and unkind, greedy, and selfish. They destroyed everything Nammu had created and donated. Their obsession for power led to irreversible damage leaving Nammu disheartened and speechless.`,
    `He was unwilling to believe his own creation could be so evil and the excruciating pain pushed him to hide himself in the remote depth of the abysses of the ocean. The eight gods followed him and built layers of rocks between Nammu and the surface of the waters to protect him. They would stay in the abysses until the order was restored and the universe found harmony again.`,
  ],
  pictureRanges: {
    firstRange: ["Katabasis1.jpg", "Katabasis2.jpg"],
    secondRange: ["Katabasis3.jpg", "Katabasis4.jpg"],
    thirdRange: "Katabasis5.jpg",
    fourthRange: ["Katabasis6.jpg", "Katabasis7.jpg"],
    fifthRange: ["Katabasis8.jpg", "Katabasis9.jpg"],
    sixthRange: "Katabasis10.jpg",
  },
};
