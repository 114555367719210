import FullScreenPicture from "../Components/FullScreenPicture";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as fasStar } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import useLocalStorage from "../Utils/useLocalStorage";
import Footer from "../Components/Footer";
import SelectInput from "../Components/SelectInput";
import ClientForm from "../Components/ClientForm";
import OrderSummary from "../Components/OrderSummary";
import { Link } from "react-router-dom";
import ErrorMessage from "../Components/ErrorMessage";
import Skeleton from "../../node_modules/react-loading-skeleton/lib/index";
import { Helmet } from "react-helmet";
import { CountProps } from "./ThanksForOrder";
import { Product } from "./Collection";

export interface Client {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  comments?: string | null;
  addressList?: Address | null;
  billingAddressList?: [Address] | null;
  newsletter: boolean;
}
interface Address {
  clientID: string;
  addressType: string;
  compagnyName: string;
  firstName: string;
  lastName: string;
  streetNumber: number;
  streetName: string;
  optionalAddressInfo: string;
  zipcode: number;
  city: string;
  country: string;
  contactPhone: number;
}

export interface ProductToOrder {
  id: string;
  quantity?: Number;
  size?: string;
  color?: string;
}

export type ProductWithSizeAndColor = ProductToOrder & Product;

export interface Order {
  _id: string;
  createDate: Date;
  validationDate: Date;
  clientID: string;
  products: ProductWithSizeAndColor[];
  shippingAddress: Address[];
  totalPrice: Number;
}

const Wishlist = ({ setCount }: CountProps) => {
  const [fullScreenPicture, setFullScreenPicture] = useState<string | null>(
    null
  );
  const [fullScreenProduct, setFullScreenProduct] = useState<Product | null>(
    null
  );

  const [order, setOrder] = useState<Order | null>(null);
  const [client, setClient] = useState<Client | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fillSize, setFillSize] = useState<boolean>(false);

  const [wishOver, setWishOver] = useState<number | null>(null);
  const [wishlist, setWishList] = useLocalStorage("wishlist-doppelganger", []);
  const [productsData, setProductsData] = useState<ProductWithSizeAndColor[]>(
    []
  );

  const removeFromWishList = (productId: string, key: number) => {
    const newWishlist = (wishlist as ProductToOrder[]).filter(
      (product, index) => product.id !== productId || index !== key
    );

    setWishList(newWishlist);
    setCount(newWishlist.length);
  };

  const isInWishListWithoutSize = (productId: string, color: string) =>
    (wishlist as ProductToOrder[]).some(
      (product) =>
        product.id === productId &&
        color === product.color &&
        product.size === null
    );

  const addProduct = (product: ProductToOrder) => {
    if (isInWishListWithoutSize(product.id, product.color ?? "")) {
      const newWishlist =
        (wishlist as ProductToOrder[])?.map((wishlistProduct) =>
          wishlistProduct.id === product.id &&
          wishlistProduct.color === product.color &&
          wishlistProduct.size === null
            ? product
            : wishlistProduct
        ) ?? [];
      setWishList(newWishlist);
      setCount(newWishlist.length);
    } else {
      setCount(wishlist.length + 1);
      setWishList([...wishlist, product]);
    }
    setWishOver(null);
  };

  const [errorWishlist, setErrorWishlist] = useState(false);
  const [errorOrder, setErrorOrder] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = "/api/wishlist";
    const productIds =
      (wishlist as ProductToOrder[])?.map((product) => product.id) ?? [];
    const getProducts = async () =>
      await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `productIds=${JSON.stringify(productIds)}`,
      })
        .then((response) => response.json())
        .then((data) => {
          const wishlistData = [...wishlist];

          (wishlist as ProductToOrder[]).forEach((wishlistProduct, index) => {
            (data.products as Product[]).forEach((product) => {
              if (wishlistProduct.id === product._id) {
                wishlistData[index] = {
                  ...product,
                  size: wishlist[index].size,
                  color: wishlist[index].color,
                };
              }
            });
          });
          setProductsData(wishlistData);
          setLoading(false);
        })
        .catch((error) => error && setErrorWishlist(error));
    productIds && getProducts();
  }, [wishlist]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleProductClick = (
    product: ProductWithSizeAndColor,
    key: number
  ) => {
    setFullScreenProduct(product);
    setFullScreenPicture(
      product?.images?.find((image) => image.color === product.color)?.url ??
        null
    );
  };

  const handleSizeChange = (size: string, id?: string) => {
    const newProductList =
      productsData?.map((product) => {
        if (product._id === id) {
          product.size = size;
        }
        return product;
      }) ?? [];
    setProductsData(newProductList);
    const newWishlist =
      (wishlist as ProductToOrder[])?.map((product) => {
        if (product.id === id) {
          product.size = size;
        }
        return product;
      }) ?? [];

    setWishList(newWishlist);
  };

  const sendRequest = async (e: any, client: Client) => {
    e.preventDefault();
    const isSizeFilled = productsData.every((product) => product.size);

    if (isSizeFilled) {
      setFillSize(false);
      const url = "/api/create-order";
      await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `productOrder=${JSON.stringify(
          productsData
        )}&client=${JSON.stringify(client)}`,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.order && data.client) {
            setOrder(data.order);
            setClient(data.client);
            setIsOpen(true);
          }
        })
        .catch((error) => error && setErrorOrder(error));
    } else {
      setFillSize(true);
    }
  };

  const remove = (id: string, key: number) => {
    removeFromWishList(id, key);
  };

  const handleScroll = () => {
    window.scrollTo(0, scrollPosition);
  };

  const productsCard =
    productsData?.map((product, key) => (
      <div key={key} className="wishlistCard">
        <div
          onClick={() => {
            setScrollPosition(window.pageYOffset);
            handleProductClick(product, key);
          }}
        >
          <div className="collection-wishlistIconCard">
            <FontAwesomeIcon
              icon={wishOver === key ? faStar : fasStar}
              className="collection-wishlistIcon"
              onMouseEnter={() => setWishOver(key)}
              onMouseLeave={() => setWishOver(null)}
              onClick={(e) => {
                e.stopPropagation();
                remove(product._id, key);
              }}
            />
          </div>
          <img
            key={key}
            aria-label="squillace 1923 Cielo glove collection"
            className="collectionPicture"
            src={
              product?.images?.find((image) => image.color === product.color)
                ?.url ?? ""
            }
            alt={`campaign ${product.name} squillace1923 gloves`}
          />
          <h4 style={{ margin: 0 }}>{product.name}</h4>
          <h5>{product.price} €</h5>
          <h5>{product.color}</h5>
          {/* <div
            style={{
              marginTop: "50px",
              textAlign: "left",
              marginBottom: "50px",
            }}
          ></div> */}
        </div>
        <SelectInput
          size={product.size ?? ""}
          handleSizeChange={handleSizeChange}
          sizeRange={product.sizeRange}
          productId={product._id}
        />
      </div>
    )) ?? [];

  const skeletons =
    (wishlist as ProductToOrder[])?.map((wish, index) => (
      <div className="wishlistCard" key={index}>
        <div className="collection-wishlistIconCard">
          <FontAwesomeIcon icon={fasStar} className="collection-wishlistIcon" />
        </div>
        {/* @ts-ignore */}
        <Skeleton count={1} color="#202020" highlightColor="#444" />
      </div>
    )) ?? [];

  return (
    <div className="content collection">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <title>
          Squillace 1923 - Add luxury leather gloves to your wishlist
        </title>

        <meta
          name="description"
          content="Squillace 1923 is the Haute Couture brand by Omega srl. 
          Luxurious and elegant leather gloves collections, made in Naples following the ancestral phases of production, entirely handmade."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="title">
        <h1>WISHLIST</h1>
      </div>
      {errorWishlist && <ErrorMessage />}

      {!loading ? (
        productsData.length ? (
          <>
            <div className="collectionRow">
              {productsCard}
              {fillSize && (
                <h6
                  className="caption"
                  style={{ color: "red", width: "100%", textAlign: "right" }}
                >
                  * Please select all Gloves Sizes before sending your request
                </h6>
              )}
            </div>
            <h2>SEND A REQUEST FOR MORE INFORMATION ABOUT AVAILABILITY</h2>

            {errorOrder && <ErrorMessage />}

            <ClientForm sendRequest={sendRequest} />
          </>
        ) : (
          <>
            <img
              aria-label="squillace 1923 Cielo glove collection"
              className="confirmation-image"
              src="/campaign/Cielo12.jpg"
              alt={`campaign squillace1923 gloves`}
            />
            <div className="confirmation-content">
              <h3 className="confirmation-text">
                Your Wishlist is empty -{" "}
                <Link
                  to="/collection"
                  style={{ fontFamily: "Cormorant Garamond", fontSize: "18px" }}
                >
                  Go here to add an item !
                </Link>
              </h3>
            </div>
          </>
        )
      ) : (
        <div className="collectionRow">{skeletons}</div>
      )}

      {fullScreenPicture && fullScreenProduct && (
        <FullScreenPicture
          picList={fullScreenProduct?.images?.map((image) => image.url) ?? []}
          fullScreenPicture={fullScreenPicture}
          setFullScreenPicture={setFullScreenPicture}
          details={fullScreenProduct}
          addProduct={addProduct}
          handleScroll={handleScroll}
        />
      )}

      <Footer />
      {order && client && isOpen && (
        <OrderSummary
          order={order}
          client={client}
          setIsOpen={setIsOpen}
          products={productsData}
        />
      )}
    </div>
  );
};

export default Wishlist;
