import CampaignZoom from "../../Components/CampaignZoom";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { campaignDoppelGanger } from "../../Utils/CampaignDefinitions";

const DoppelGanger = () => {
  const [fullScreenPicture, setFullScreenPicture] = useState<
    string | undefined
  >(undefined);
  const picList = [
    "/doppelganger/Doppelganger1.jpg",
    "/doppelganger/Doppelganger2.jpg",
    "/doppelganger/Doppelganger3.jpg",
    "/doppelganger/Doppelganger4.jpg",
    "/doppelganger/Doppelganger5.jpg",
    "/doppelganger/Doppelganger6.jpg",
    "/doppelganger/Doppelganger7.jpg",
    "/doppelganger/Doppelganger8.jpg",
    "/doppelganger/Doppelganger9.jpg",
    "/doppelganger/Doppelganger10.jpg",
    "/doppelganger/Doppelganger11.jpg",
    "/doppelganger/Doppelganger12.jpg",
    "/doppelganger/Doppelganger13.jpg",
    "/doppelganger/Doppelganger14.jpg",
    "/doppelganger/Doppelganger15.jpg",
    "/doppelganger/Doppelganger16.jpg",
    "/doppelganger/Doppelganger17.jpg",
    "/doppelganger/Doppelganger18.jpg",
  ];
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    window.scrollTo(0, scrollPosition);
  };

  const renderVerticalRange = (range: string[]) => (
    <div className="campaignPicRange pointer">
      {range.map((picture, key) => (
        <img
          key={key}
          aria-label="Campaign DoppelGanger squillace 1923 leather gloves"
          className="campaignPicVertical"
          src={`/doppelganger/${picture}`}
          alt={`doppelganger ${campaignDoppelGanger.title} squillace1923 gloves`}
          onClick={() => {
            setScrollPosition(window.pageYOffset);
            setFullScreenPicture(`/doppelganger/${picture}`);
          }}
        />
      ))}
    </div>
  );

  const renderHorizontalRange = (range: string) => (
    <div className="campaignPicRange">
      <img
        key={3}
        aria-label="Campaign DoppelGanger squillace 1923 leather gloves"
        className="campaignPicHorizontal pointer"
        src={`/doppelganger/${range}`}
        alt={`doppelganger ${campaignDoppelGanger.title} squillace1923 gloves`}
        onClick={() => {
          setScrollPosition(window.pageYOffset);
          setFullScreenPicture(`/doppelganger/${range}`);
        }}
      />
    </div>
  );
  const ranges = campaignDoppelGanger.pictureRanges;

  const description = campaignDoppelGanger.description.split(".");

  return (
    <div className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <title>
          Squillace 1923 - Luxury leather gloves campaign handmade in Italy -
          DoppelGanger season 22
        </title>

        <meta
          name="description"
          content="Squillace 1923 is the Haute Couture brand by Omega srl. 
    Luxurious and elegant leather gloves collections, made in Naples following the ancestral phases of production, entirely handmade."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="title">
        <h1>{campaignDoppelGanger.title.toUpperCase()}</h1>
        <h1>/ˈdɒp(ə)lˌɡaŋə,ˈdɒp(ə)lˌɡɛŋə/</h1>
        <h2>
          {description.map((text, index) => (
            <>
              {text + "."}
              <br></br>
              {index === 0 && <br></br>}
            </>
          ))}
        </h2>
      </div>
      <div className="campaignPic">
        {renderHorizontalRange(ranges.firstRange)}
        {renderVerticalRange(ranges.secondRange)}
        {renderHorizontalRange(ranges.thirdRange)}
        {renderHorizontalRange(ranges.fourthRange)}
        {renderVerticalRange(ranges.fifthRange)}
        {renderHorizontalRange(ranges.sixthRange)}
        {renderVerticalRange(ranges.seventhRange)}
        {renderHorizontalRange(ranges.eightRange)}
        {renderVerticalRange(ranges.ninethRange)}
        {renderHorizontalRange(ranges.tenthRange)}
      </div>

      {fullScreenPicture && (
        <CampaignZoom
          picList={picList}
          fullScreenPicture={fullScreenPicture}
          setFullScreenPicture={setFullScreenPicture}
          handleScroll={handleScroll}
        />
      )}
    </div>
  );
};

export default DoppelGanger;
